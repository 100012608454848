<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <!-- QUERY DATE-RANGE -->
      <v-col cols="12" lg="5">
        <v-row>
          <v-col>
            <DateRangePicker @update="onDateRangeUpdated"
              :def-dates="dates">
            </DateRangePicker>
          </v-col>
          <!-- <v-col>
            <ScopeSelector vertical
              @org-selected="onOrganizationSelected"
              @mch-selected="onMerchantSelected">
            </ScopeSelector>
          </v-col> -->
        </v-row>
      </v-col>

      <!-- SEARCH -->
      <v-col cols="12" sm="6" lg="4">
        <v-card class="my-0" style="height: 100%;">
          <v-card-text>
            <v-container>
              <v-row align="end">
                <v-col cols="12" class="pa-0">
                  <v-radio-group row v-model="form.search.searchBy">
                    <template v-slot:label>
                      <div>{{ $t('views.cardpay.orders.searchBy') }}<br></div>
                    </template>
                    <v-radio :label="$t('views.cardpay.orders.ddpgOrderId')" value="orderNo"></v-radio>
                    <!-- <v-radio :label="$t('views.orders.merchantOrderId')" value="merchant_order_number"></v-radio> -->
                    <v-radio :label="$t('views.cardpay.orders.store')" value="storeId"></v-radio>
                    <v-radio :label="$t('views.cardpay.orders.authCode')" value="authCode"></v-radio>
                    <v-radio :label="$t('views.cardpay.orders.last4No')" value="last4No"></v-radio>
                    <v-radio :label="$t('views.cardpay.orders.acquirerTrxNo')" value="acquirerTrxNo"></v-radio>
                    <!-- <v-radio :label="$t('views.orders.merchant')" value="merchant_name"></v-radio> -->
                    <!-- <v-radio :label="$t('views.orders.amount')" value="amount"></v-radio> -->
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-form @submit.native.prevent @submit="search">
                    <v-text-field outlined prepend-icon="mdi-magnify"
                      v-model="form.search.keyword"
                      placeholder="(Press ENTER to search)" clearable>
                    </v-text-field>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>  
          </v-card-text>
        </v-card>
          
      </v-col>

      <v-col cols="12" sm="6" lg="2" style="height: 100%;" class="d-flex align-start flex-column">
        <!-- <v-btn x-large block color="warning" @click="exportOrderList">
          <v-icon left>mdi-file-export</v-icon>
          {{ $t('views.cardpay.orders.downloadExcel') }}
        </v-btn> -->
        <v-btn block large color="grey darken-3" @click="forceRefresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>

      <!-- LIST OF ORDERS -->
      <v-col cols="12">

        <v-card class="mt-0">
          <v-card-text>

            <SSRTable ref="list-orders"
              :data-url="loadUrl" :data-key="'payments'" :schema="schema">

              <div slot="status" slot-scope="{item}">
                <!-- -1:有誤, 0:未付款, 1:已付款, 2:已取消, 3:已退款 -->
                <!-- <v-icon v-if="item.status == 0">mdi-progress-clock</v-icon>
                <v-icon v-else-if="item.status === 1" color="success">mdi-check-circle-outline</v-icon>
                <v-icon v-else-if="item.status === -1" color="error">mdi-alert</v-icon>
                <v-icon v-else-if="item.status === 2" color="warning">mdi-cancel</v-icon>
                <v-icon v-else-if="item.status === 3" color="info">mdi-cancel</v-icon>
                <span v-else>{{ item.status }}</span> -->
                <span v-if="item.status == 0">未付款</span>
                <span v-else-if="item.status === 1">已付款</span>
                <span v-else-if="item.status === -1" style="color:red">錯誤</span>
                <span v-else-if="item.status === 2">已取消</span>
                <span v-else-if="item.status === 3">已退款</span>
                <span v-else>{{ item.status }}</span>
              </div>

              <div slot="amount" slot-scope="{item}">
                {{ item.currency }}<br>
                $ {{ Number(item.amount).toLocaleString() }}
              </div>

              <div slot="createTime" slot-scope="{item}">
                {{ tsToDateString(item.createTime) }}
              </div>
              <!-- <div slot="payer_name" slot-scope="{item}">
                <strong>{{ item.payer_name }}</strong><br>
                {{ item.payer_phone }}
              </div> -->

              <!-- <div slot="payment_type" slot-scope="{item}">
                <div v-if="getPaymentServiceIcon(item.payment_service_type || item.payment_type)">
                  <img :src="getPaymentServiceIcon(item.payment_service_type || item.payment_type)" style="height: 24px;">
                </div>
                <div v-else>{{ item.payment_service_type }}</div>
              </div> -->

              <div slot="actions" slot-scope="{item}">
                <v-btn fab x-small color="primary" @click="showInfoDialog(item)">
                  <v-icon>mdi-information-variant</v-icon>
                </v-btn>
              </div>
            </SSRTable>

          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <!-- Dialog for showing detail and transactional history of the selected order -->
    <CardpayOrderDetailDialog :show.sync="dialog.info.show"
      :payment-order-id="dialog.info.orderNo">
    </CardpayOrderDetailDialog>
  </v-container>
</template>

<script>
  import SSRTable from '@/components/SSRTable';
  import DateRangePicker from '@/components/DateRangePicker';
  // import ScopeSelector from '@/components/ScopeSelector';
  import { GetIconMixin } from '@/mixins/get-icons';

  import CardpayOrderDetailDialog from '@/views/dashboard/subviews/CardpayOrderDetailDialog';

  import axios from 'axios';
  import moment from 'moment';

  export default {
    components: {
      SSRTable,
      DateRangePicker,
      // ScopeSelector,
      CardpayOrderDetailDialog
    },
    mixins: [
      GetIconMixin
    ],

    mounted() {

    },

    methods: {
      onDateRangeUpdated(dates) {
        this.dates = dates;
      },
      // onOrganizationSelected(org) {
      //   this.scope.organization = org;
      //   // this.loadStats();
      // },
      // onMerchantSelected(merchant) {
      //   this.scope.merchant = merchant;
      //   // this.loadStats();
      // },
      search() {
        if (this.form.search.keyword && this.form.search.keyword.length) {
          this.form.search.queryString = `&${this.form.search.searchBy}=${this.form.search.keyword}`;
          // this.form.search.queryString = `&searchBy=${this.form.search.searchBy}&searchKeyword=${this.form.search.keyword}`;
        } else {
          this.form.search.queryString = '';
        }
        //NOTE: search keyword turns null when 'cleared' by user??
      },
      forceRefresh() {
        this.$refs['list-orders'].loadData();
      },

      showInfoDialog(order) {
        this.dialog.info.orderNo = order['orderNo'];
        this.dialog.info.show = true;
      },

      exportOrderList() {
        let from = moment(this.dates[0]).startOf('day');
        let to = moment(this.dates[1]).endOf('day');
        window.open(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orders/export?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
          + `&from=${from.unix()}&to=${to.unix()}`);
      },

      tsToDateString(ts) {
        if (ts) {
          return moment.unix(ts).format('YYYY/MM/DD HH:mm:ss');
        }
        return '';
      }
    },

    data() {
      return {
        dates: [
          moment().startOf('day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ],
        scope: {
          // organization: null,
          // merchant: null
        },

        form: {
          search: {
            searchBy: 'orderNo',
            keyword: '',
            queryString: ''
          }
        },
        dialog: {
          info: {
            show: false,
            orderNo: '',
            // paymentOrderId: ''
            // order: { },
            // paymentOrderId: '',
            // transactions: [],
            // transactionSchema: [
            //   { value: 'payment_order_id', text: 'Order ID' },
            //   { value: 'transaction_id', text: 'Transaction ID' },
            //   { value: 'bank_transaction_id', text: 'Bank Transaction ID' },
            //   { value: 'amount', text: '$' },
            //   { value: 'status', text: 'Status' },
            //   { value: 'status_code', text: 'Code' },
            //   { value: 'message', text: 'Message' },
            //   { value: 'created_at', text: 'Time' }
            // ],
            // data: [
            //   { label: 'Payment Order ID', key: '' },
            //   { label: 'Organization', key: '' },
            //   { label: 'Merchant', key: '' },
            //   { label: 'Merchant Order ID', key: '' },
            //   { label: 'Amount', key: '' },
            //   { label: 'Currency', key: '' },
            //   { label: 'Payment Order ID', key: '' },
            //   { label: 'Paid Via', key: '' },
            //   { label: 'Status', key: '' },
            //   { label: 'Created At', key: '' }
            // ]
          }
        },
        expansion: {
          detail: {
            show: false,
            context: { }
          }
        }
      };
    },

    computed: {
      loadUrl() {
        let from = moment(this.dates[0]).startOf('day');
        let to = moment(this.dates[1]).endOf('day');
        return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/cardpay/payments?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
          + `&from=${from.unix()}&to=${to.unix()}`
          // + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
          // + (this.scope.merchant? `&storeId=${this.scope.merchant['merchant_id']}`: '')
          + `${this.form.search.queryString}`;
      },

      schema() {
        return [
          { value: 'storeId', text: this.$t('views.cardpay.orders.store'), sortable: false },
          { value: 'orderNo', text: this.$t('views.cardpay.orders.ddpgOrderId'), sortable: false },
          { value: 'trxId', text: this.$t('views.cardpay.orders.trxId'), sortable: false },
          { value: 'acquirerTrxNo', text: this.$t('views.cardpay.orders.acquirerTrxNo'), sortable: false },
          { value: 'authCode', text: this.$t('views.cardpay.orders.authCode'), sortable: false, align: 'center', width: 128  },
          { value: 'last4No', text: this.$t('views.cardpay.orders.last4No'), sortable: false, align: 'center', width: 128  },
          { value: 'amount', text: this.$t('views.cardpay.orders.amount'), align: 'end', width: 128 },
          { value: 'status', text: this.$t('views.cardpay.orders.status'), sortable: false, align: 'center',},
          { value: 'createTime', text: this.$t('views.cardpay.orders.createdAt') },
          // { value: 'updateTime', text: this.$t('views.cardpay.orders.updatedAt') },
          { value: 'actions', text: this.$t('views.cardpay.orders.actions'), sortable: false },
        ];
      },
      // transactionSchema() {
      //   return [
      //     { value: 'payment_order_id', text: this.$t('views.orders.dialogs.info.paymentOrderId') },
      //     { value: 'transaction_id', text: this.$t('views.orders.dialogs.info.transactionId') },
      //     { value: 'bank_transaction_id', text: this.$t('views.orders.dialogs.info.bankTransactionId') },
      //     { value: 'amount', text: '$' },
      //     { value: 'status', text: this.$t('views.orders.dialogs.info.status') },
      //     { value: 'status_code', text: this.$t('views.orders.dialogs.info.code') },
      //     { value: 'message', text: this.$t('views.orders.dialogs.info.message') },
      //     { value: 'created_at', text: this.$t('views.orders.dialogs.info.time') }
      //   ];
      // }
    }
  }
</script>
